import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './BigArticleTitle.module.sass';

const BigArticleTitle = ({ children, className, ...props }) => (
    <h1 {...props} className={cs(styles.articleTitle, className)}>{children}</h1>
);

BigArticleTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

BigArticleTitle.defaultProps = {
  className: '',
};

export default BigArticleTitle;